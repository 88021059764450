import PageProduct from '@/components/PageProduct';

export default PageProduct;

export async function getStaticProps() {
  return {
    props: {
      path: '/keno',
      theme: 'keno',
      description:
        'KENO auf einen Blick ✓: Online-Spielschein der täglichen Zahlenlotterie KENO, aktuelle Gewinnzahlen inkl. Ziehungsvideo und eine kurze Spielanleitung',
      title: 'KENO ► Lotterie-Übersicht | LOTTO.de',
      contents: {
        name: 'contents',
        nodes: [
          {
            template: 'lotto:components/pageHeading',
            value: 'KENO',
          },
          {
            template: 'lotto:components/section',
            contents: {
              name: 'contents',
              nodes: [
                {
                  text: '<p><strong>Hinweis: </strong>Am 1. Juli 2021 ist der <a href="/ueber/gluecksspielstaatsvertrag">Glücksspielstaatsvertrag 2021</a> in Kraft getreten. KENO gilt aufgrund der täglichen Möglichkeit zur Spielteilnahme als Lotterie, an der gesperrte Spielerinnen und Spieler nicht teilnehmen dürfen. Wenn Sie auf LOTTO.de einen <a href="/keno/spielen">KENO-Spielschein</a> vorbereiten, erfolgt nach Weiterleitung auf die Webseite der für Sie zuständigen Landeslotteriegesellschaft ein Abgleich mit dem Spielersperrsystem.</p>\n',
                  template: 'lotto:components/richtext',
                },
              ],
            },
          },
          {
            template: 'lotto:components/section',
            link: {
              path: '/keno/zahlen',
            },
            title: 'Die aktuellen Gewinnzahlen',
            linkLabel: 'Alle Gewinnzahlen & Quoten',
            contents: {
              name: 'contents',
              nodes: [
                {
                  template: 'lotto:components/winningNumbers',
                  variant: 'keno',
                },
                {
                  template: 'lotto:components/drawVideo',
                  variant: 'keno',
                },
              ],
            },
          },
          {
            template: 'lotto:components/section',
            title: 'KENO auf einen Blick',
            contents: {
              name: 'contents',
              nodes: [
                {
                  template: 'lotto:components/iconList',
                  contents: {
                    name: 'contents',
                    nodes: [
                      {
                        text: 'Je Ziehung 1 €, 2 €, 5 € oder 10 € zuzüglich Bearbeitungsgebühren je Spielschein',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-money',
                        title: 'Spieleinsatz',
                      },
                      {
                        text: '1.000.000 € gewinnen Sie mit einer Chance von 1 zu 2.147.181',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-draw',
                        title: 'Gewinnwahrschein­lichkeit',
                      },
                      {
                        text: 'Täglich um 19:10 Uhr werden die neuen KENO-Gewinnzahlen gezogen',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-tv',
                        title: 'Ziehungen',
                      },
                      {
                        text: 'Sie können direkt an mehreren Ziehungen (Min. 1, Max. 28) teilnehmen',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-calendar',
                        title: 'Spielschein-Laufzeit',
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            template: 'lotto:components/section',
            title: 'So funktioniert KENO',
            contents: {
              name: 'contents',
              nodes: [
                {
                  path: '/keno/contents/01/contents/0',
                  template: 'lotto:components/cardSlider',
                  contents: {
                    name: 'contents',
                    nodes: [
                      {
                        text: '<p>Auf einem <a href="/keno/spielen">KENO-Spielschein</a> befinden sich 5 Tippfelder mit jeweils 70 Zahlen. In einem Tippfeld können Sie zwischen 2 und 10 Zahlen ankreuzen. Die Anzahl der angekreuzten Zahlen in einem Tippfeld definiert Ihren KENO-Typ: bei 3 getippten Zahlen spielen Sie z.B. KENO-Typ 3. Jeder KENO-Typ hat einen eigenen Gewinnplan mit festen Quoten.</p>\n\n<p>Sie können ein Tippfeld anklicken und die Zahlen selbst ankreuzen oder Sie nutzen bequem unsere Quick-Tipps: damit können Sie ein oder mehrere Tippfelder schnell und bequem per Zufall ausfüllen.</p>\n',
                        template: 'lotto:components/cardSliderItem',
                        labelNext: 'Nächster Schritt',
                        labelPrev: 'Vorheriger Schritt',
                        title: '1. KENO-Zahlen tippen',
                      },
                      {
                        name: '00',
                        path: '/keno/contents/01/contents/0/contents/00',
                        id: 'b55ffa60-9112-495f-afe9-7b0f0ccf888e',
                        text: '<p>Die Losnummer können Sie einfach per Eingabe von Nummern ändern oder per Zufall eine neue Nummer generieren. Diese Nummer ist gleichzeitig auch Ihr Tipp für die <a href="/keno/spielregeln">Zusatzlotterie plus 5</a>, die nur in Verbindung mit KENO gespielt werden kann. Für eine Teilnahme können Sie die Zusatzlotterie einfach anklicken.</p>\n',
                        template: 'lotto:components/cardSliderItem',
                        labelNext: 'Nächster Schritt',
                        title: '2. Losnummer und Zusatzlotterie wählen',
                        labelPrev: 'Vorheriger Schritt',
                      },
                      {
                        name: '02',
                        path: '/keno/contents/01/contents/0/contents/02',
                        id: '7c07e848-d6d5-4a42-a71d-90c41cdc7a2b',
                        text: '<p>Wählen Sie eine Teilnahme für 1, 2, 3, 4, 5, 6, 7, 14 oder 28 Ziehungen für Ihren <a href="/keno/spielen">KENO-Spielschein</a> aus.</p>\n\n<p>Auch den Spieleinsatz pro <a href="/keno/ziehung">Ziehung</a> können Sie zwischen 1 €, 2 €, 5 € und 10 € selbst festlegen. Da es sich bei KENO um eine Lotterie mit festen Quoten handelt, haben Sie mit einem höheren Spieleinsatz auch die Chance auf einen höheren Gewinn den jeweilgen KENO-Typ.</p>\n',
                        template: 'lotto:components/cardSliderItem',
                        labelNext: 'Nächster Schritt',
                        title:
                          '3. Anzahl der Ziehungen und Einsatz pro Ziehung festlegen',
                        labelPrev: 'Vorheriger Schritt',
                      },
                      {
                        name: '03',
                        path: '/keno/contents/01/contents/0/contents/03',
                        id: '65fecb08-23ec-43e8-b01e-76e677e85ef2',
                        text: '<p>Ihren <a href="/keno/spielen">KENO-Spielschein</a> können Sie bei der Landeslotteriegesellschaft Ihres Bundeslandes abgeben. Falls Sie dort bereits registrierter Nutzer sind, können Sie sich einfach anmelden und den Spielschein abgeben. Falls Sie noch kein Kunde einer Landeslotteriegesellschaft sind, müssen Sie erst den Registrierungsprozess in Ihrem Bundesland abschließen.</p>\n',
                        template: 'lotto:components/cardSliderItem',
                        labelNext: 'Nächster Schritt',
                        labelPrev: 'Vorheriger Schritt',
                        title: '4. Anmelden oder registrieren',
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            template: 'lotto:components/section',
            title: 'Sicherheit bei LOTTO.de',
            hideLine: true,
            contents: {
              name: 'contents',
              nodes: [
                {
                  template: 'lotto:components/iconList',
                  contents: {
                    name: 'contents',
                    nodes: [
                      {
                        text: 'Wir leiten Ihren Spielschein-Tipp mit einer sicheren Verbindung an die Landes­lotterie­gesellschaft Ihres Bundeslandes weiter - dort können Sie online spielen.',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-shield',
                        title: 'Sicher & legal',
                      },
                      {
                        text: 'Rechtliche Grundlage für erlaubtes Glücksspiel in Deutschland sind der Glücksspiel­staatsvertrag und die Glücksspiel­gesetze der Länder.',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-court',
                        title: 'Staatlich erlaubt',
                      },
                      {
                        text: 'Im Gewinnfall haben Sie bei staatlich erlaubten Lotterien einen Rechtsanspruch auf eine garantierte Auszahlung durch die Landes­lotterie­gesellschaften.',
                        template: 'lotto:components/iconListItem',
                        icon: 'icon-big-payoff',
                        title: 'Garantierte Auszahlung',
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            template: 'lotto:components/section',
            hideLine: true,
            contents: {
              name: 'contents',
              nodes: [
                {
                  game: '3',
                  template: 'lotto:components/linkGame',
                },
              ],
            },
          },
        ],
      },
    },
  };
}
